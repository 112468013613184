import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useOfficialChatStore = defineStore('officialChat', {
  actions: {
		index: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/official-chats`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/official-chats/${id}`),
    store: (officialChat) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/official-chats`, officialChat),
		update: (id, officialChat) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/official-chats/${id}`, officialChat),
  }
})
